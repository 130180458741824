@import "../theme.scss";

.Page {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: 100vh;

    :global {
        .gd-dash-header-wrapper {
            // offset the header so that the dashboard top bar is not hidden behind the application header when scrolling
            top: $header-height !important;
        }
    }
}

.Main {
    flex: 1 0 auto;
    padding: $spacing * 2 + 56px $spacing $spacing * 2 $spacing;
    max-width: 1200px;
    width: calc(100% - (#{$spacing} * 2));
    margin: 0 auto;
}

.VerticalCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.Inverse {
    background-color: $color-paper-inverse;
    color: $color-text-inverse;
}
