@import "./theme.scss";

body {
    margin: 0;
    text-rendering: optimizeLegibility;
    font-family: $font-family;
}

a {
    color: $color-primary;

    &:hover,
    &:visited {
        color: $color-primary;
    }
    &:active,
    &:focus {
        color: $color-primary-darker;
    }
}

h1 {
    font-size: 38px;
    margin-top: 0em;
    margin-bottom: 0.25em;
}
h2 {
    font-size: 30px;
    margin-top: 2em;
    margin-bottom: 0.25em;
}
h3 {
    font-size: 18px;
    margin-top: 2em;
    margin-bottom: 0.25em;
}
