@import "../../theme.scss";
@import "../../mixins.scss";

.Header {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    min-height: $header-height;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    z-index: 101; // must be higher than z-index of the sdk-ui-dashboard header (which is 100 currently)
    padding: 0;
    color: #4a4a4a;
    background: #fdfdfd;
    border-bottom: 1px solid $color-active;
}

.Aside {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-end;
    padding-right: 10px;
}

.Logo {
    font-weight: bold;
    text-transform: uppercase;
}

.LogoImage {
    height: 35px;
}

.VerticalDelimiter {
    width: 2px;
    background-color: $color-border;
    align-self: stretch;
    margin: $spacing 0;
}

.Centered {
    margin: 0 $spacing * 0.5;
    padding: 0 $spacing;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
}

.Link {
    margin: 0 $spacing * 0.5;
    padding: 0 $spacing;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.9);
    font-size: 15px;
    border-bottom: 5px solid transparent;
    transition: $transition-fast;

    &:hover,
    &:visited,
    &:active,
    &:focus {
        color: inherit;
        border-color: rgb(70, 78, 86);
    }

    &Active,
    &Active:hover,
    &Active:visited {
        border-color: $color-active;
    }
}

.BurgerMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.BurgerToggle {
    @include reset-appearance;
    margin-left: $spacing;
    font-size: 1.5em;
    margin-top: -4px;
    width: 2em;
    height: 2em;
    text-align: center;
    cursor: pointer;
    border-radius: $border-radius;

    &:hover {
        background-color: $color-accent-lighter;
    }
}

.BurgerContent {
    position: fixed;
    left: 0;
    top: 64px;
    min-width: 230px;
    max-width: 100vw;
    background-color: $color-paper;
    z-index: 1000;
    padding-bottom: $spacing;
    @include box-shadow;

    .Link,
    .Centered {
        padding-bottom: $spacing * 0.25;
        margin-top: $spacing;
    }
}
