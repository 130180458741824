@import "../../theme.scss";
@import "../../mixins.scss";

.Input {
    @include reset-appearance;
    width: 100%;
    display: block;
    font-size: inherit;
    border-bottom: 2px solid $color-grey;

    &::placeholder {
        color: $color-grey;
    }

    &:hover,
    &:focus {
        border-color: $color-primary;
    }
}

.Error {
    border-color: transparentize($color-error, 0.5);
}
